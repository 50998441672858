<template>
    <div class="solutionDetails" >
        <!-- <div class="banner ">
            <img :src="baseURL+solutionInfo.solutionBanner" alt="">
        </div> -->
        <div class="module_title">{{solutionInfo.solutionName }}</div>
        <div :class="isModule ? 'module-box1' : 'module-box2'" id="module-boxs" v-if="characterList.length > 0">
            <div class="module" v-for="(item,index) in characterList" :key="index">            
                <div class="module_content wrap">
                    <div :id="'scroll'+index" class="module_content_img" :class="['module_content_img',index == 0 ? 'active' : '']">
                        <img :src="baseURL+item.pic" alt="">
                    </div>
                    <div class="module_content_text">
                        <div class="title">{{ item.name }}</div>
                        <div class="info">{{ item.characterDesc }}</div>
                    </div>
                    <div v-if="index == 0 && isModule" class="openBox" @click="moduleState">查看更多<img class="imgs" src="@/assets/images/arrows.png" alt=""></div>
                    <div v-if="index == (characterList.length-1) && !isModule" class="openBox" @click="moduleState('close')">收起<img class="imgs" src="@/assets/images/arrows3.png" alt=""></div>
                </div>
            </div>
        </div>
        <div v-else class="">
            暂无数据
        </div>
        <!-- 应用案例 -->
        <div class="news-container" v-if="caseList.length > 0">
            <div class="titles">应用案例</div>
            <div class="news-list" ref="newsList"
                :style="{
                    'margin-left':`${distance}px`,
                    'transition':'0.5s',
                    'justify-content':screenWidth>1900&&caseList.length<5?'center':'unset'
                }">
                <div class="news-content" v-for="(item,i) in caseList" :key="i" @click="pageJumps(`/caseDetails?id=${item.id}`)">
                    <div class="pic"><img :src="baseURL+item.casePic" alt=""></div>
                    <div class="text-box">
                        <div class="text">{{ item.caseName }}</div>
                        <div class="more">查看更多</div>
                    </div>
                </div>
            </div>
            <div class="btn" v-if="caseList.length >= 4">
                <button class="pre-btn"  @click="prev"><i class="el-icon-arrow-left"></i></button>
                <button class="next-btn"  @click="next"><i class="el-icon-arrow-right"></i></button>
            </div>
        </div>
        <!-- 推荐方案 -->
        <div class="recommendScheme">
            <div class="recommendScheme_head">
                <div class="title">推荐方案</div>
                <div class="introduce">为了更好地产品体验，我们为您精选了相关产品</div>
            </div>
            <div class="recommendScheme_content wrap">
                <el-carousel :interval="5000" height="435px"  arrow="always" indicator-position="outside">
                    <el-carousel-item v-for="(item,k) in hotSolutionList" :key="k">
                        <div class="box">
                            <div class="box_left">
                                <img :src="baseURL+item.solutionPic" alt="">
                            </div>
                            <div class="box_right">
                                <div class="scheme_title">{{item.solutionName}}</div>
                                <div class="scheme_content">{{item.solutionDesc}}</div>
                                <el-button class="viewMore" type="text" @click="pageJumps(`/solutionDetails?id=${item.id}`)">查看更多 <i class="el-icon-arrow-right"></i></el-button>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <!-- <contactUs /> -->
    </div>
</template>

<script>
import {baseURL} from "@/utils/request"
import contactUs from '@/components/contactUs.vue'
import {solutionDetails,hotSolution} from '@/api/solution'
import {CaseList} from '@/api/header'
    export default {
        name:'solutionDetails',
        components:{
            contactUs,
        },
        data(){
            return{
                baseURL,
                distance:0,
                id:"",
                solutionInfo:{},
                characterList:[],

                hotSolutionList:[],  // 热门解决方案列表
                caseList:[],
                screenWidth:null,
                listWidth:0, // 列表宽度
                isModule:true,
                scrollTop:0,
            }
        },
        watch:{
            $route: {
                handler() {
                    this.id = this.$route.query.id;
                    this.getSolutionDeatils()
                    this.getCaseList()
                },
                deep: true,
            }
        },

        created(){
            this.id=this.$route.query.id
            // 应用案例列表
            this.getCaseList()
        },

        updated(){
            if (this.caseList.length > 0) {
                this.listWidth=this.$refs.newsList.scrollWidth                
            }
            if (this.characterList.length > 0){
                this.characterList.forEach((val,index) => {
                    if(index == 0){
                        val.scrollTop = 100
                    }else{
                        val.scrollTop = index*700+100
                    }
                })      
                window.addEventListener('scroll',() => {
                    this.scrollTop = document.documentElement.scrollTop / this.$store.state.scale
                    this.characterList.forEach((val,index) => {
                        if(val.scrollTop && this.scrollTop >= val.scrollTop){
                            if(document.querySelector(`#scroll${index}`) && !document.querySelector(`#scroll${index}`).className.includes('active')){
                                document.querySelector(`#scroll${index}`).className = `module_content_img active module_content_img_${val.imgPosition}`
                            }
                            // console.log("document.querySelector(`#scroll${index}`).className",document.querySelector(`#scroll${index}`).className);
                        }
                    })
            })
            }
            
        },

        mounted(){
            this.getSolutionDeatils()
            this.getHotSolutionList()
            this.screenWidth = document.body.clientWidth
            window.onresize = () => {
                return (() => {
                    this.screenWidth = document.body.clientWidth
                })()
            }
        },
        methods:{
            moduleState(val){
                this.isModule = !this.isModule;
                if (val == 'close') {
                    document.documentElement.scrollTop = 0;
                    document.body.scrollTop = 0;
                }
            },
            // 应用案例
            getCaseList(){
                let data = {
                    pageNum:1,
                    pageSize:1000,
                    solutionId:this.id
                }
                CaseList(data).then(res => {
                    console.log("res 应用案例列表",res);
                    if(res.code == 200){
                        this.caseList = res.rows
                    }
                })
            },
            prev(){
                let width = this.distance + this.$refs.newsList.offsetWidth
                let offsetLeft=this.$refs.newsList.offsetLeft
                if(offsetLeft!=0){
                    if(width < this.listWidth){
                        this.distance=this.distance+386
                    } else{
                        this.distance=0
                    }
                }
            },
            next(){
                let width = this.$refs.newsList.offsetWidth
                console.log(width)
                if(width < this.listWidth){
                    this.distance=-(Math.abs(this.distance)+386)
                } else{
                    // this.distance=0
                }
            },
            // 根据id获取解决方案详情
            getSolutionDeatils(){
                solutionDetails(this.id).then(res=>{
                    this.solutionInfo=res.data
                    this.characterList=res.data.characterList
                })
            },

            // 获取热门解决方案
            getHotSolutionList(){
                let para={isHot:1}
                hotSolution(para).then(res=>{
                    this.hotSolutionList=res.rows.slice(0,3)
                })
            },

            pageJumps(path){
                this.$router.push({path},() => {})
            },
        }
    }
</script>

<style lang="less" scoped>
.solutionDetails{
    padding: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;   
    .titles{
        font-size: 54px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 74.09px;
        color: rgba(31, 35, 41, 1);
        text-align: center;
        margin-bottom: 64px;
    }
    .news-container{
        width: 100%;
        padding: 72px 0;
        overflow: hidden;
        position: relative;
    }
    .news-list{
        margin-top: 64px;
        display: flex;
        gap: 20px;
        position: relative;
        z-index: 1;
    }
    .news-content{
        width: 386px;
        height: 341px;
        flex-shrink: 0;  // 不缩小
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0px 2px 24px  rgba(15, 128, 36, 0.2);
        background: #fff;
        .pic{
            width: 100%;
            height: 270px;
            background: #fff;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .main{
            background: #fff;
            height: calc(100% - 163px);
            padding: 16px;
            box-sizing: border-box;
            .news-label{
                height: 25px;
                display: flex;
                .label-item{
                    padding: 4px 16px;
                    margin-right: 8px;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 4px;
                }
                .label-item:nth-child(1){
                    color: rgba(36, 227, 81, 1);
                    background: rgba(69, 186, 85, 0.1);
                }
                .label-item:nth-child(2){
                    color: rgba(255, 141, 26, 1);
                    background: rgba(255, 141, 26, 0.1);
                }
            }

            .name{
                margin-top: 16px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(31, 35, 41, 1);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                overflow: hidden;
            }
            .desc{
                margin-top: 9px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(31, 35, 41, 0.8);
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3; /* 这里是超出几行省略 */
                overflow: hidden;
            }
        }
    }

    .btn{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        button{
            width: 32px;
            height: 32px;
            border: unset;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 2px 24px rgba(15, 128, 36, 0.2);
            i{
                // color:  rgba(51, 51, 51, 0.4);
                color: #349c57;
                font-weight: 700;
            }
            &:hover{
                cursor: pointer;
                i{
                    color:  rgba(65, 75, 89, 1);
                }
            }
        }
    }
    .text-box{
        display: flex;
        justify-content: space-between;
        line-height: 72px;
        box-sizing: border-box;
        padding: 0 10px;
        .text{
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 230px;
        }
        .more{
            box-sizing: border-box;
            text-align: center;
            border-radius: 4px;
            color: rgba(52, 156, 87, 1);
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
        }
    }
    .banner{
        max-height: 540px;
        // width: 1920px;
        width: 100%;
        // background: url('@/assets/images/slution/banner.png') no-repeat;
        // background-size:  100% 100%;
        >img{
            width: 100%;
            height: 100%;
        }
    }
    .title{
        font-size: 28px;
        font-weight: bold;
        color: rgba(31, 35, 41, 1);
        margin: 0 0 12px 0;
    }
    .info{
        font-size: 16px;
        color: rgba(0, 0, 0, .5);
        width: 490px;
    }
    .module_title{
        text-align: center;
        font-size: 36px;
        font-weight: 500;
        color: rgba(31, 35, 41, 1);
        margin: 0 0 0 0;
        background: #f7f9ff;
        height: 130px;
        line-height: 130px;
    }
    .module-box1{
        width: 1920px;
        height:720px;
        overflow: hidden;  
        transition: height .3s;
    }
    .module-box2{
        width: 1920px;
        height: auto;		
		transition: height .3s;
    }
    .module{
        width: 1920px;
        height:720px;
        background: #fff;
        padding: 100px 0;
        box-sizing: border-box;
        position: relative;
        &:nth-child(odd){
            background: #F0F2F7;
            .module_content{
                flex-direction:row-reverse;
                .module_content_img{
                    transform: translateX(150%);
                    &.active{
                        opacity: 1;
                        transform: translateX(0%)
                    }
                }
            }
        }    
        .openBox{
            position: absolute;
            bottom: 20px;
            left:0;
            right: 0;
            margin: auto;
            font-size: 14px;
            font-weight: 400;
            width: 100px;
            height: 28px;
            border-radius: 4px;
            border: 1px solid #349c57;
            color: #349c57;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .imgs{
                width: 14px;
                height: 14px;
                margin-left: 6px;
            }
        }  
        .module_content{
            display: flex;
            justify-content: space-between;
            .module_content_text{
                margin: 153px 0 0 0;
            }
            .module_content_img{
                width: 650px;
                height: 520px;
                border-radius: 12px;
                background: rgba(240, 240, 240, 1);
                box-shadow: 0px 0px 24px 0px rgba(32, 37, 45, 0.06);
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: all 1s;
                transform: translateX(-150%);
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
                &.active{
                    opacity: 1;
                    transform: translateX(0%)
                }
            }

             /** .module_content_img_left{
                transform: translateX(-150%);
                &.active{
                    opacity: 1;
                    transform: translateX(0%)
                }
            }
            .module_content_img_right{
                transform: translateX(150%);
                &.active{
                    opacity: 1;
                    transform: translateX(0%)
                }
            }*/

        }
    }
    // .module:not(:nth-child(1)){
    //     height: 720px;
    // }
    .recommendScheme{
        width: 1920px;
        height: 807px;
        background: url(@/assets/images/slution/tjfaBg.png) no-repeat;
        background-size: 100% 100%;
        padding: 73px 0 0 0;
        box-sizing: border-box;
        .recommendScheme_head{
            margin: 0 0 44px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title{
                color: #fff;
                font-size: 36px;
                margin: 0 0 16px 0;
            }
            .introduce{
                font-size: 16px;
                color: rgba(255, 255, 255, .8);
            }
        }
        .recommendScheme_content{
            /deep/.el-carousel{
                .el-carousel__container{
                    .el-carousel__item {
                        // padding: 0 80px;
                        // box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .box{
                            width: 966px;
                            height: 435px;
                            background: #fff;
                            border-radius: 8px;
                            overflow: hidden;
                            display: flex;
                            // transform: scale(.6);
                            .box_left{
                                height: 435px;
                                width: 614px;
                                border-radius: 8px;
                                background: rgba(120, 134, 150, 1);
                                img{
                                    width: 614px;
                                    height: 435px;
                                }
                            }
                            .box_right{
                                flex: 1;
                                padding: 89px 50px 0 50px;
                                position: relative;
                                .scheme_title{
                                    font-size: 20px;
                                    color: rgba(31, 35, 41, 1);
                                }
                                .scheme_content{
                                    color: rgba(0, 0, 0, .5);
                                    margin: 24px 0 0 0;
                                    font-size: 14px;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 8; /*超出几行后省略号*/
                                    overflow: hidden;
                                }
                                .viewMore{
                                    position: absolute;
                                    right: 60px;
                                    bottom: 70px;
                                    padding: 0;
                                    span{
                                        color: rgba(52, 156, 87, 1);
                                    }
                                }
                            }
                        }
                    }
                    .el-carousel__arrow{
                        background: rgba(255, 255, 255, .2);
                        i{
                            color: #000;
                            font-weight: bold;
                            font-size: 18px;
                        }
                        &:hover{
                            background: rgba(255, 255, 255, .6);
                        }
                    }
                    .el-carousel__arrow--left{
                        left: 0;
                    }
                    .el-carousel__arrow--right{
                        right: 0;
                    }
                }
                .el-carousel__indicators{
                    margin: 70px 0 0 0;
                    >li{
                        padding: 0px 10px;
                        button{
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}
</style>
