<template>
  <div id="app">
    <div class="container">
      <!--      <div ref="vue2ScaleBox"-->
      <!--           id="vue2ScaleBox"-->
      <!--           :style="{-->
      <!--                    ...style,-->
      <!--                    width: width + 'px',-->
      <!--            }"-->
      <!--      >-->
      <!-- 头部菜单  -->
      <Header/>
      <div style="height:92px"></div>
      <router-view/>

      <!-- 页脚 -->
      <Footer/>
      <el-backtop :bottom="100" :visibility-height="50"></el-backtop>
      <!--      </div>-->
    </div>

  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"
// import ScaleBox from '@/components/ScaleBox/index.vue'
// ScaleBox
export default {
  components: {Header, Footer, },
  data() {
    return {
      scale: 0,
      width: 1920,
      height: window.innerHeight, // 屏幕高度,
      delay: 200,
      style: {
        // transform: "scale(var(--scale))",
        // transformOrigin: "0 0",
        zoom: 'var(--scale)',
        // zIndex: 2,
        // height: 'var(--height)',
      },
    }
  },
  mounted() {
    this.setScale();
    window.addEventListener("resize", this.debounce(this.setScale));
  },
  methods: {
    getScale() {
      const {width, height} = this;
      // const wh = window.innerHeight * window.devicePixelRatio / height;
      const ww = (window.innerWidth - 20) / width;
      // console.log('getScale', ww, wh, width, height, window.innerWidth, window.innerHeight)
      return ww;
    },
    setScale() {
      console.log('setScale')
      this.scale = this.getScale();
      this.$store.commit('saveScale', this.scale)  // 保存缩放比例用于跳转产品页面位置
      if (this.$refs.vue2ScaleBox) {
        this.$refs.vue2ScaleBox.style.setProperty("--scale", this.scale);
      }
    },
    debounce(fn, delay) {
      const delays = delay || this.delay;
      let timer;
      return function () {
        const th = this;
        const args = arguments;
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          timer = null;
          fn.apply(th, args);
        }, delays);
      };
    },
  },
}
</script>

<style lang="less" scoped>
#app {
  font-family: 'AliRegular';
  background: rgba(247, 249, 255, 1);

  .container {
    min-width: 1220px;
    margin: 0 auto;

    /deep/ .el-backtop {
      .el-icon-caret-top {
        color: rgba(52, 156, 87, 1);
      }
    }
  }
}


</style>
