<template>
    <div class="solution-container wrap">
        <div class="module-title">
            <div class="title">行业解决方案</div>
            <div class="sub-title">众多客户信赖之选</div>
        </div>

        <div class="solution-list">
            <div class="solution-item" v-for="(item,i) in list.slice(0,4)" :key="i">
                <div class="name">{{item.solutionName}}</div>
                <div class="desc">{{item.solutionDesc}}</div>
                <div class="look-btn" @click="pageJumps(`/solutionDetails?id=${item.id}`)">
                    <span>立即查看</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {solutionList} from "@/api/home"
export default {
  name: '',
  data () {
    return {
        list:[],
    }
  },
  created () {
  },
  mounted () {
    this.getSolutionList()
  },
  methods: {
    // 获取解决方案数据
    getSolutionList(){
        solutionList().then(res=>{
            this.list=res.data
            // console.log("this.list",this.list);
        })
    },

    // 页面跳转
    pageJumps(path){
        this.$router.push({path},()=>{})
    },
  },
}
</script>
<style lang="less" scoped>
    .solution-container{
        margin-top: 72px;
        // padding: 40px 5.7%;
        .solution-list{
            padding: 40px 0 72px 0;
            display: flex;
            justify-content: space-between;
            .solution-item{
                width: 393px;
                height: 248px;
                padding: 20px;
                box-sizing: border-box;
                border-radius: 4px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 2px 32px  rgba(31, 38, 32, 0.04);
                margin-right: 20px;
                position: relative;
                .name{
                    font-size: 20px;
                    font-weight: 600;
                    color: rgba(31, 35, 41, 1);
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                    overflow: hidden;
                }
                .desc{
                    margin-top: 9px;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(129, 136, 145, 1);
                    line-height: 22px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 7; /* 这里是超出几行省略 */
                    overflow: hidden;
                }
                .look-btn{
                    position: absolute;
                    bottom: 20px;
                    // left: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    width: 88px;
                    height: 28px;
                    border-radius: 4px;
                    border:  1px solid rgba(52, 156, 87, 1);
                    color: rgba(52, 156, 87, 1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
            }
            .solution-item:hover .look-btn{
                background: rgba(52, 156, 87, 1);
                color: #fff;
            }
        }
    }

</style>
