<template>
    <div class="about-container">
        <div class="wrap">
            <div class="module-title">
                <div class="title">关于比昂</div>
                <div class="sub-title">{{homeTitles.about}}</div>
            </div>

            <div class="advantage">
                <div class="advantage-item" v-for="(item,k) in list" :key="k" 
                    :class="{active:idx == k}" 
                    @mouseenter="mouseEnter(k)" 
                    @click="pageJumps('/aboutUsDetails?module=gsjj')">

                    <img :src="baseURL+item.advPic" alt="">
                    <div class="text">
                        <div class="name">{{item.advTitle}}</div>
                        <div class="intro" v-if="idx == k">{{item.advDesc}}</div>
                    </div>
                </div>

                <!-- <div class="advantage-item" :class="{active : idx == 2}" @mouseenter="mouseEnter(2)">
                    <img src="@/assets/images/home/adv2.jpg" alt="">
                    <div class="text">
                        <div class="name">行业优势</div>
                        <div class="intro" v-if="idx==2">行业方面的优势</div>
                    </div>
                </div>

                <div class="advantage-item" :class="{active : idx == 3}" @mouseenter="mouseEnter(3)">
                    <img src="@/assets/images/home/adv3.jpg" alt="">
                    <div class="text">
                        <div class="name">服务优势</div>
                        <div class="intro" v-if="idx==3">服务方面的优势</div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import {baseURL} from "@/utils/request"
import {aboutUs} from "@/api/home"
export default {
  name: '',
  data () {
    return {
        baseURL,
        idx:0,
        list:[],
    }
  },
    computed:{
        homeTitles(){
            return this.$store.state.homeTitles
        }
    },
  created () {
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    mouseEnter(val){
        this.idx = val
    },

    // 获取关于精准信息
    getInfo(){
        aboutUs().then(res=>{
             console.log(res,"关于精准信息")
            this.list=res.data
        })
    },

    pageJumps(path){
        this.$router.push({path})
    },

  },
}
</script>
<style lang="less" scoped>
.about-container{
    padding: 72px 0 76px 0;
    background: rgba(240, 255, 255, 1);
    .advantage{
        margin-top: 44px;
        display: flex;
        justify-content: space-between;
        .advantage-item{
            width: 290px;
            height: 384px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            transition: all 0.8s;
            cursor: pointer;
            &:not(:last-child){
                margin-right: 20px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .text{
                width: 100%;
                height: 100%;
                padding-left: 40px;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5);
                .name{
                    font-size: 24px;
                    font-weight: 700;
                    color: rgba(255, 255, 255, 1);
                    position: absolute;
                    left: 24px;
                    bottom: 24px;
                }
                .intro{
                    width: 540px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: rgba(255, 255, 255, 1);
                    margin-top: 8px;
                }
            }
        }
        .active{
            width: 600px;
            .text{
                display: flex;
                flex-direction: column;
                justify-content: center;
                .name{
                    position: unset;
                }
            }
        }
    }
}

</style>
