<template>
    <div class="home">
        <Banner />
        <Solution />
        <Product />
        <ScienceTechnologyHelp />
        <About />
        <!-- <Partner /> -->
        <News />
    </div>
</template>
<script>
import Banner from "@/components/Banner.vue"
import Solution from "./components/solution.vue"
import Product from "./components/product.vue"
import ScienceTechnologyHelp from "./components/scienceTechnologyHelp.vue"
import About from "./components/about.vue"
import Partner from "./components/partner.vue"
import News from "./components/news.vue"

import {homeTitles} from "@/api/home"
export default {
  name: '',
  components:{Banner,Solution,Product,ScienceTechnologyHelp,About,Partner,News},
  data () {
    return {
        
    }
  },
  created () {
  },
  mounted () {
    this.getHomeTitles()
  },
  methods: {
    // 获取首页各标题描述
    getHomeTitles(){
        homeTitles().then(res=>{
            // console.log(res,"首页标题描述")
            this.$store.commit('saveHomeTitles',res.data[0])
        })
    },
  },
}
</script>
<style lang="less" scoped>
.home{}

</style>
