import request from "@/utils/request"

// banner
export function bannerList(data){
    return request({
        url:'/home/getBannersByBannerPage',
        method:'POST',
        data
    })
}

// 首页各模块标题描述
export function homeTitles(data){
    return request({
        url:'/home/getHomeTitles',
        method:'POST',
        data
    })
}

// 解决方案
export function solutionList(){
    return request({
        url:'/system/solution/getSolutionHome',
        method:'GET',
    })
}

// 热门产品
export function hotProduct(data){
    return request({
        url:'/home/getHotProduct',
        method:'POST',
        data
    })
}

// 经典案例
export function hotCase(data){
    return request({
        url:'/home/getHotCase',
        method:'POST',
        data
    })
}

// 关于精准
export function aboutUs(){
    return request({
        url:'/system/bus/getAboutJz',
        method:'GET',
    })
}

// 合作伙伴
export function partnerList(){
    return request({
        url:'/home/getPartner',
        method:'POST',
    })
}

// 行业动态及资讯
export function newsList(){
    return request({
        url:'/system/news/selectBottomNewsList',
        method:'GET',
    })
}
// 查询字典数据列表
export function listData(query) {
    return request({
      url: '/system/dict/data/list',
      method: 'get',
      params: query
    })
  }