<template>
    <div class="contactUs">
        <div>懂理论，更懂实践</div>
        <div>快速匹配人工渠道为您服务</div>
        <el-button type="success" plain @click="dialogVisible = true">联系我们</el-button>

        <el-dialog
            title="联系我们"
            :visible.sync="dialogVisible"
            width="575px"
            custom-class="dialogStyle"
            center>
            <div class="content">
                <div class="item">
                    <div class="item-label">联系电话：</div>
                    <div class="item-value">13228103000（微信同号）</div>
                </div>
                <div class="item">
                    <div class="item-label">联系地址：</div>
                    <div class="item-value">成都市金牛区西宸国际A座15楼1501</div>
                </div>
                <div class="item">
                    <div class="item-label">生产基地：</div>
                    <div class="item-value">成都市蒲江县工业大道2号</div>
                </div>
            </div>
            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
            </div> -->
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                dialogVisible: false,
            }
        },
    }
</script>

<style lang="less" scoped>
.contactUs{
    width: 1920px;
    height: 362px;
    // background: #2B82E5;
    background: url('@/assets/images/contactUs.png') no-repeat;
    background-size:  100% 100%;
    padding: 97px 0 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    >div:nth-child(1){
        font-size: 36px;
    }
    >div:nth-child(2){
        font-size: 16px;
        margin: 9px 0 34px 0;
    }
    .el-button{
        width: 183px;
        height: 52px;
        font-size: 18px;
    }

    /deep/.dialogStyle{
        border-radius: 4px;
        .el-dialog__header{
            .el-dialog__title{
                font-size: 24px;
                font-weight: 600;
                color: #1f2329;
            }
        }
        .el-dialog__body{
           padding: 25px 40px 30px;
        }
        .content{
            display: flex;
            flex-direction: column;
            gap: 40px;
            .item{
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding-left: 30px;
                border-left: 2px solid rgba(52, 156, 87, 1);

                .item-label{
                    font-size: 16px;
                    color: rgba(52, 156, 87, 1);
                }
                .item-value{
                    font-size: 20px;
                    color: rgba(0, 0, 0, 0.7);
                }
            }
        }
    }
}
</style>
