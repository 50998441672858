<template>
    <div class="news-container">
        <div class="module-title">
            <div class="title">企业动态及行业资讯</div>
        </div>

        <!-- 轮播 -->
        <!-- <el-carousel height="375px" :autoplay="false" arrow="none" class="wrap">
            <el-carousel-item v-for="k in Math.ceil(list.length / 4)" :key="k" class="carsouel-item">
                <div class="news-content"  v-for="(item,i) in list.slice((k - 1) * 4, k * 4)" :key="i">
                    <div class="pic"><img src="@/assets/images/test2.jpg" alt=""></div>
                    <div class="main">
                        <div class="news-label">
                            <div class="label-item" v-for="(val,i) in item.label" :key="i">林草叶</div>
                        </div>
                        <div class="name">{{item.name}}</div>
                        <div class="desc">{{item.desc}}</div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel> -->

        <div class="news-list" ref="newsList"
            :style="{
                'margin-left':`${distance}px`,
                'transition':'0.5s',
                'justify-content':screenWidth>1900&&list.length<7?'center':'unset'
            }">
            <div class="news-content" v-for="(item,i) in list" :key="i" @click="pageJumps(`/dynamicDetails?id=${item.id}&typeId=${item.newsType}`)">
                <div class="pic"><img :src="baseURL+item.newsPic" alt=""></div>
                <div class="main">
                    <div class="news-label" v-if="item.tag">
                        <div class="label-item" v-for="(val,i) in item.tag.split(',')" :key="i">{{val}}</div>
                    </div>
                    <!-- <div v-else class="news-label"></div> -->
                    <div class="name">{{item.newsTitle}}</div>
                    <div class="desc">{{item.newsDesc}}</div>
                </div>
            </div>
        </div>


        <div class="btn">
            <button class="pre-btn"  @click="prev"><i class="el-icon-arrow-left"></i></button>
            <button class="next-btn"  @click="next"><i class="el-icon-arrow-right"></i></button>
        </div>
        <img class="newsBg" src="@/assets/images/home/news_bg.png" alt="">
    </div>
</template>
<script>
import {baseURL} from "@/utils/request"
import {newsList} from "@/api/home"
export default {
    name: '',
    data () {
        return {
            baseURL,
            listWidth:0, // 列表宽度
            distance:0,
            list:[],
            screenWidth:null,

            preDisabled:false,
            nextDisabled:false,
        }
    },
    created () {
        this.getNewsList()
    },
    mounted(){
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },

    updated(){
        this.listWidth=this.$refs.newsList.scrollWidth
    },


    methods: {
        // 获取新闻列表
        getNewsList(){
            newsList().then(res=>{
                console.log(res,"新闻列表")
                this.list=res.rows
            })
        },

        prev(){
            let width = this.distance + this.$refs.newsList.offsetWidth
            let offsetLeft=this.$refs.newsList.offsetLeft

            if(offsetLeft!=0){
                if(width < this.listWidth){
                    this.distance=this.distance+310
                } else{
                    this.distance=0
                }
            }
        },
        next(){
            let width = this.$refs.newsList.offsetWidth
            if(width < this.listWidth){
                this.distance=-(Math.abs(this.distance)+310)
            } else{
                // this.distance=0
            }
        },

        // 页面跳转
        pageJumps(path){
            this.$router.push({path},()=>{})
        },
    },
}
</script>
<style lang="less" scoped>
.news-container{
    width: 100%;
    padding: 72px 0;
    background: rgba(52, 156, 87, 1);
    overflow: hidden;
    position: relative;
    .module-title{
        position: relative;
        z-index: 1;
        .title{
            color: #fff;
        }
    }

    .carsouel-item{
        display: flex;
    }

    .news-list{
        margin-top: 64px;
        display: flex;
        gap: 20px;
        position: relative;
        z-index: 1;
    }
    .news-content{
        width: 290px;
        height: 373px;
        flex-shrink: 0;  // 不缩小
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;
        .pic{
            width: 100%;
            height: 163px;
            background: #fff;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .main{
            background: #fff;
            height: calc(100% - 163px);
            padding: 16px;
            box-sizing: border-box;
            .news-label{
                height: 25px;
                display: flex;
                .label-item{
                    padding: 4px 16px;
                    margin-right: 8px;
                    font-size: 12px;
                    font-weight: 400;
                    border-radius: 4px;
                }
                .label-item:nth-child(1){
                    color: rgba(36, 227, 81, 1);
                    background: rgba(69, 186, 85, 0.1);
                }
                .label-item:nth-child(2){
                    color: rgba(255, 141, 26, 1);
                    background: rgba(255, 141, 26, 0.1);
                }
            }

            .name{
                margin-top: 16px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(31, 35, 41, 1);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                overflow: hidden;
            }
            .desc{
                margin-top: 9px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(31, 35, 41, 0.8);
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3; /* 这里是超出几行省略 */
                overflow: hidden;
            }
        }
    }

    .btn{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        button{
            width: 32px;
            height: 32px;
            border: unset;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            i{
                color:  rgba(51, 51, 51, 0.4);
                font-weight: 700;
            }
            &:hover{
                cursor: pointer;
                i{
                    color:  rgba(65, 75, 89, 1);
                }
            }
        }
    }
    .newsBg{
        position: absolute;
        top: -215px;
        left: 50%;
        transform: translateX(-50%);
    }
}

</style>
